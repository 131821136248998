<template>
  <div class="content">
    <div class="inner-content">
      <h1>{{ msg }}</h1>
      <p>
        This tool is used to provide access to the documentation portal by creating an account within our Azure AD B2C instance. This is done by 
        generating an activation link which can be shared with a customer or send directly towards their work email.
      </p>
      <p>
        Please fill in the user's name, email address and select an organization. The organization selection is based on the available organizations with the documentation portal <a href="https://gitlab.energy21.com/e21-docs/docs-build_and_deploy/-/blob/master/authorization.json">backend</a>. Next, either generate an activation link or use the provided SMTP settings to send an invite.
      </p><p>
        For a guide how to this all (technically) works / use this project, read the <a
          href="https://gitlab.energy21.com"
          target="_blank"
          rel="noopener"
        >documentation</a>
      </p>
      <h3>Fill in your customers details</h3>
      <form
        id="app"
      >
        <p v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </p>

        <p>
          <label for="name">Name</label>
          <input
            id="name"
            v-model="name"
            type="text"
            name="name"
          >
        </p>

        <p>
          <label for="email">Email</label>
          <input
            id="email"
            v-model="email"
            type="text"
            name="email"
          >
        </p>

        <p />

        <div>
          <label for="form-control">Organization</label>
          <select
            class="form-control"
            @change="changeOrganization($event)"
          >
            <option
              value=""
              selected
              disabled
            />
            <option
              v-for="organizations in organizations"
              :key="organizations.id"
              :value="organizations.id"
            >
              {{ organizations.name }}
            </option>
          </select>

          <br><br>
        </div>

        <div class="buttons">
          <button
            type="submit"
            @click.prevent="checkForm('link')"
          >
            Generate Link
          </button>
          <button
            type="submit"
            @click.prevent="checkForm('mail')"
          >
            Send Invite
          </button>
        </div>
      </form>
      <div v-if="status">
        <p v-if="status === 'SuccessLink'">
          Share the following link with your customer, this is a personalized link so only share it with the intended recipient!
        </p>
        <p v-else-if="status ==='SuccessEmail'">
          Invite succesfully send via mail.
        </p>
        <p v-else>
          Invite could not be send or unable to generate link. The following error occured:
        </p>
        <div
          v-if="statusmsg"
          class="link-box"
        >
          <p>{{ statusmsg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "querystring";
export default {
  name: "EmailForm",
  props: {
    msg: String,
  },
  data: function () {
    return {
      errors: [],
      name: null,
      email: null,
      organization: null,
      status: null,
      statusmsg: null,
      organizations: [
        { name: "Energy21", id: 1 },
        { name: "BASF - België", id: 2 },
        { name: "Eneco Energy and Trade", id: 3 },
        { name: "Uniper NL", id: 4 },
        { name: "PVNED", id: 5 },
        { name: "Uniper BE", id: 6 },
        { name: "Vattenfall", id: 7 },
        { name: "Alliander EXE", id: 8 },
        { name: "Eneco Zakelijk", id: 9 },
        { name: "GasTerra", id: 10 },
        { name: "ENGIE", id: 11 },
        { name: "Gazprom", id: 12 },
        { name: "Greenchoice", id: 13 },
        { name: "TenneT", id: 14 },
        { name: "Essent", id: 15 },
        { name: "AXPO", id: 16 },
        { name: "Powerdale (BASF)", id: 17 },
        { name: "Scholt", id: 18 },
        { name: "Eneco", id: 19 },
        { name: "PZEM", id: 20 },
        { name: "RWE", id: 21 },
        { name: "Shell Energy Europe", id: 22 },
        { name: "USG", id: 23 },
        { name: "ABN Amro", id: 24 },
        { name: "Dreamsolution", id: 25 },
        { name: "VattenfallNordics", id: 26 },
        { name: "Cegeka", id: 27 },
        { name: "Dynamic Energy Trading", id: 28 },
        { name: "Energie Beheer Nederland", id:29},
        { name: "PURE ENERGIE", id:30},
        { name: "Intern - Energy21", id:31},
      ],
      selectedOrganization: null,
    };
  },
  methods: {
    checkForm: function (type) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }

      if (!this.email) {
        this.errors.push("Email required.");
      } else {
        if (!this.validEmail(this.email)) {
          this.errors.push("Not a valid email!");
        }
      }

      if (!this.selectedOrganization) {
        this.errors.push("Organization required.");
      }

      if (!this.errors.length) {
        if (type === "mail") {
          if (this.correctMailSettings()) {
            this.sendInvite();
          }
        } else {
          this.generateLink();
        }
      } else {
        alert("Fix your inputs!");
      }
    },
    changeOrganization(event) {
      this.selectedOrganization =
        event.target.options[event.target.options.selectedIndex].text;
    },
    validEmail: function (email) {
      const re = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      return re.test(email);
    },
    generateLink: function () {
      const url = "/generate_link";
      const data = {
        name: this.name,
        email: this.email,
        organization: this.selectedOrganization,
      };
      const options = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };

      axios
        .post(url, qs.stringify(data), options)
        .then((res) => {
          this.status = "SuccessLink";
          this.statusmsg = res.data;
        })
        .catch((err) => {
          this.status = "Link could not be generated :(";
          this.statusmsg = err;
        });
    },
    sendInvite: function () {
      const url = "/send_invite";
      const data = {
        user: JSON.stringify({
          name: this.name,
          email: this.email,
          organization: this.selectedOrganization,
        }),
        email: JSON.stringify({
          SMTPServer: this.$store.state.email.SMTPServer.value,
          SMTPPort: this.$store.state.email.SMTPPort.value,
          SMTPUsername: this.$store.state.email.SMTPUsername.value,
          SMTPPassword: this.$store.state.email.SMTPPassword.value,
          SMTPFromAddress: this.$store.state.email.SMTPFromAddress.value,
        }),
      };
      const options = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };

      axios
        .post(url, qs.stringify(data), options)
        .then((res) => {
          this.status = "SuccessEmail";
          this.statusmsg = null;
        })
        .catch((err) => {
          this.status = "Mail could not be send :(";
          this.statusmsg = err.response.data;
        });
    },
    correctMailSettings: function () {
      if (!this.$store.state.email.SMTPServer.value) {
        this.errors.push("SMTP Server required.");
        return false;
      }

      if (!this.$store.state.email.SMTPPort.value) {
        this.errors.push("SMTP Port required.");
        return false;
      }

      if (!this.$store.state.email.SMTPUsername.value) {
        this.errors.push("SMTP Username required.");
        return false;
      }
      return true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
